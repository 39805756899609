<template>
  <v-list dense>
    <div v-for="(review, index) in productReviews" :key="`product-review-${index}`">
      <product-review-item :product-id="value.id" :value="review" :index="index" :user-id="authUserId" />
      <v-divider v-if="index + 1 < productReviews.length" />
    </div>
    <v-list-item v-if="total > productReviews.length">
      <v-list-item-content>
        <v-btn text small :loading="loading" @click="onLoadNextPageReviews">
          {{ $t('components.website.productReviews.loadMoreReviews') }}
        </v-btn>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="productReviews.length === 0">
      <v-list-item-content>
        <v-list-item-title v-if="!loading">{{ $t('components.website.productReviews.empty') }}</v-list-item-title>
        <v-progress-circular v-if="loading" indeterminate />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import ProductReviews from '@peynman/press-vue-core/mixins/ProductReviews'
  import ProductReviewItem from './ProductReviewItem.vue'

  export default {
    name: 'ProductReviewsList',
    components: { ProductReviewItem },
    mixins: [ProductReviews],
    props: {
      value: Object,
    },
    data: vm => ({
      internalValue: vm.value ?? {},
      loading: false,
      total: 0,
      page: -1, // next page reviews function adds 1
    }),
    computed: {
      product () {
        return this.internalValue
      },
    },
    watch: {
      value () {
        this.internalValue = this.value ?? {}
      },
    },
    mounted () {
      this.onLoadNextPageReviews()
    },
    methods: {
      onLoadNextPageReviews () {
        this.loading = true
        this.loadPaginatedReviews(this.page + 1).then(json => {
          this.$set(this.internalValue, 'reviews', [
            ...(this.internalValue?.reviews ?? []),
            ...json.items,
          ])
          this.total = json.total
          this.page = json.currPage
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
