<template>
  <website>
    <template v-if="!loading && internalValue" slot="toolbar">
      <website-breadcrumbs :product="internalValue" />
    </template>
    <product-details-skeleton-loader v-if="loading" />
    <product-details v-if="!loading && internalValue" :value="internalValue" />
  </website>
</template>

<script>
  import Website from '../templates/Website/Website.vue'
  import ProductDetails from '../components/Product/ProductDetails.vue'
  import ProductDetailsSkeletonLoader from '../components/Product/ProductDetailsSkeletonLoader.vue'
  import WebsiteBreadcrumbs from '../components/Breadcrumbs/WebsiteBreadcrumbs.vue'

  export default {
    name: 'ProductPage',
    components: {
      Website,
      ProductDetails,
      ProductDetailsSkeletonLoader,
      WebsiteBreadcrumbs,
    },
    props: {
      objectId: [String, Number],
      value: Object,
    },
    data: vm => ({
      loading: false,
      internalValue: vm.value ?? null,
    }),
    watch: {
      objectId () {
        if (!this.value && !this.loading) {
          this.fetchProductDetails()
        }
      },
      value () {
        this.internalValue = this.value
        if (!this.internalValue && !this.loading) {
          this.fetchProductDetails()
        }
      },
    },
    mounted () {
      if (!this.internalValue) {
        this.fetchProductDetails()
      }
    },
    methods: {
      fetchProductDetails () {
        this.loading = true
        this.$store.dispatch('repos/fetchProductDetails', { id: this.objectId })
          .then(product => {
            this.internalValue = product
            this.$store.dispatch('analytics/trackProductView', product)
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
  }
</script>
