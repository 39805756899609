<template>
  <v-card
    :dark="theme.website.product.card.dark"
    :light="theme.website.product.card.light"
    :color="theme.website.product.card.color"
  >
    <v-card-title>{{ $t('components.website.productReviews.title') }}</v-card-title>
    <v-divider />
    <v-card-text class="pa-0">
      <product-reviews-list :value="value" @input="onReviewEdited" />
    </v-card-text>
    <v-divider />
    <v-expansion-panels v-if="isLoggedIn">
      <v-card-text v-if="hasSentReview">{{ $t('components.website.productReviews.alreadySent') }}</v-card-text>
      <v-expansion-panel v-else>
        <v-expansion-panel-header>{{ $t('components.website.productReviews.yourReview') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <product-review-form v-model="review" :product-id="productId" @input="onNewReviewAdded" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-text v-else>
      <span>{{ $t('components.website.productReviews.loginFirst') }}</span>
      <signing-dialog :value="showSigninDialog" :tab="0">
        <template v-slot:activator="{on}">
          <v-btn
            v-if="!isLoggedIn"
            :small="$vuetify.breakpoint.xs"
            class="mx-1"
            v-on="on"
          >{{ $t('components.website.appbar.auth') }}</v-btn>
        </template>
      </signing-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import ProductReviews from '@peynman/press-vue-core/mixins/ProductReviews'
  import SigningDialog from '../../Auth/SigningDialog.vue'
  import ProductReviewForm from './ProductReviewForm.vue'
  import ProductReviewsList from './ProductReviewsList.vue'

  export default {
    name: 'ProductReviewsCard',
    components: {
      SigningDialog,
      ProductReviewForm,
      ProductReviewsList,
    },
    mixins: [
      Themeable,
      ProductReviews,
    ],
    props: {
      value: Object,
    },
    data: vm => ({
      review: {},
      showSigninDialog: false,
    }),
    computed: {
      productId () {
        return this.value?.id
      },
    },
    methods: {
      onReviewEdited (review, index) {

      },
      onNewReviewAdded (review) {

      },
    },
  }
</script>
