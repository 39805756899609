<template>
  <v-row>
    <v-col cols="12" sm="4">
      <v-skeleton-loader type="image, actions" />
    </v-col>
    <v-col cols="12" sm="8">
      <v-skeleton-loader type="card-heading, list-item-three-line, actions" />
    </v-col>
    <v-col cols="12">
      <v-skeleton-loader type="card-heading, list-item-three-line" />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ProductDetailsSkeletonLoader',
  }
</script>
