<template>
  <div>
    <v-fade-transition>
      <v-overlay
        :value="showImage"
        :opacity="0.8"
        class="d-flex flex-column align-center justify-center"
      >
        <v-btn fab style="position: fixed; left: 50px; top: 50px; z-index: 1000;" @click="showImage = null">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div style="position: fixed; width: 90%; max-height: 90%; left: 5%; top: 5%; overflow: scroll">
          <product-images-carousel
            :value="carousel"
            :images="carouselImages"
            @show-image="showImage = null"
          />
        </div>
      </v-overlay>
    </v-fade-transition>
    <v-row no-gutters>
      <v-col cols="12" sm="4">
        <v-card
          :dark="theme.website.product.card.dark"
          :light="theme.website.product.card.light"
          :color="theme.website.product.card.color"
        >
          <product-images-carousel
            v-model="carousel"
            :images="carouselImages"
            :image-thumbnail-func="getImageThumbnail"
            @show-image="onShowImage"
          />
          <v-card-actions>
            <product-images-slider
              v-model="carousel"
              :images="carouselImages"
              :image-thumbnail-func="getImageThumbnail"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" :class="`${!$vuetify.breakpoint.xs ? 'ps-2' : 'pt-2'}`">
        <v-card
          :dark="theme.website.product.card.dark"
          :light="theme.website.product.card.light"
          :color="theme.website.product.card.color"
        >
          <product-card-ribbon :value="value" />
          <product-links :value="value" class="p-absolute" style="z-index: 1; left: 5px; top: 3px;" />
          <v-card-title class="justify-center">{{ productTitle }}</v-card-title>
          <product-categories-subtitle :value="value" horizontal />
          <v-card-subtitle class="py-0 my-1">
            <product-card-price :value="value" />
          </v-card-subtitle>
          <v-card-text v-if="productHasDescription">
            <div v-html="productDescriptionContent" />
            <v-schema-renderer
              v-if="productDescriptionSchema"
              :children="productDescriptionSchema.children"
              :bindings="schemaBindings"
              :components-dictionary="widgetsDictionary"
              :pre-processor="preProcessWidget"
            />
          </v-card-text>
          <v-card-text v-if="productGuide">
            <v-dialog max-width="766">
              <template v-slot:activator="{on}">
                <v-btn v-on="on">{{ $t('components.website.productCard.guide') }}</v-btn>
              </template>
              <v-card
                dark
                data-track-content
                data-content-piece="ProductGuid"
                :data-content-name="`Product-${productId}`"
                data-content-target=""
              >
                <v-card-text class="pa-5 ck-content" v-html="productGuide" />
              </v-card>
            </v-dialog>
          </v-card-text>
          <product-add-to-cart-form :value="value" @image-index="onChangeImage" />
        </v-card>
      </v-col>
      <v-col cols="12" class="pt-2">
        <product-reviews-card :value="value" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'
  import WidgetsRenderer from '@peynman/press-vue-core/mixins/WidgetsRenderer'
  import Product from '@peynman/press-vue-core/mixins/Product'
  import ProductAddToCartForm from './ProductForm/ProductAddToCartForm.vue'
  import ProductCardRibbon from './ProductCard/ProductCardRibbon.vue'
  import ProductLinks from './ProductCard/ProductLinks.vue'
  import ProductCategoriesSubtitle from './ProductCard/ProductCategoriesSubtitle.vue'
  import ProductReviewsCard from './ProductReviews/ProductReviewsCard.vue'
  import ProductImagesCarousel from './ProductCard/ProductImagesCarousel.vue'
  import ProductImagesSlider from './ProductCard/ProductImagesSlider.vue'
  import ProductCardPrice from './ProductCard/ProductCardPrice.vue'

  export default {
    name: 'ProductDetails',
    components: {
      ProductAddToCartForm,
      ProductCardRibbon,
      ProductLinks,
      ProductCategoriesSubtitle,
      ProductReviewsCard,
      ProductImagesCarousel,
      ProductImagesSlider,
      ProductCardPrice,
    },
    mixins: [
      Themeable,
      Product,
      WidgetsRenderer,
      FormValidations(),
    ],
    props: {
      value: Object,
    },
    data: vm => ({
      carousel: 0,
      showImage: null,
    }),
    computed: {
      schemaBindings () {
        return [{
          name: 'product',
          type: 'default',
          default: this.product,
        }]
      },
    },
    methods: {
      onShowImage (index) {
        this.showImage = this.carouselImages?.[index]
      },
      onChangeImage (index) {
        this.carousel = index
      },
    },
  }
</script>
