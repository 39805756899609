<template>
  <v-form ref="form" v-model="valid">
    <span>{{ $t('components.website.productReviews.rate') }}</span>
    <v-rating v-model="stars" clearable dense />
    <v-text-field
      v-model="name"
      :rules="[ getRequiredRule() ]"
      :label="$t('components.website.productReviews.name')"
      :hint="$t('components.website.productReviews.nameHint')"
    />
    <v-textarea
      v-model="message"
      :rules="[ getRequiredRule(), getMaxLengthRule(250) ]"
      :label="$t('components.website.productReviews.message')"
      :counter="250"
    />
    <v-btn color="green" :loading="submitting" @click="onSubmitReview">
      {{ reviewId ? $t('components.website.productReviews.editReview') : $t('components.website.productReviews.submit') }}
    </v-btn>
  </v-form>
</template>

<script>
  import FormValidations from '@peynman/press-vue-core/mixins/FormValidations'

  export default {
    name: 'ProductReviewForm',
    mixins: [FormValidations()],
    props: {
      productId: Number,
      reviewId: Number,
      value: Object,
    },
    data: vm => ({
      valid: false,
      name: vm.value?.data?.name,
      message: vm.value?.message,
      submitting: false,
      stars: vm.value?.stars,
    }),
    methods: {
      submitReview (message, stars, data) {
        if (this.reviewId) {
          return this.$store.dispatch('product/updateProductReview', {
            id: this.productId,
            reviewId: this.reviewId,
            message,
            stars,
            data,
          })
        } else {
          return this.$store.dispatch('product/addProductReview', {
            id: this.productId,
            message,
            stars,
            data,
          })
        }
      },
      onSubmitReview () {
        this.$refs.form.validate()

        if (this.valid) {
          this.submitting = true
          this.submitReview(this.message, this.stars, {
            name: this.name,
          })
            .then(json => {
              if (json.message) {
                this.$store.commit('snackbar/addMessage', {
                  message: json.message,
                  color: 'success',
                })
              }
              this.$emit('input', json.review)
            })
            .catch(error => {
              this.$store.commit('snackbar/addMessage', {
                message: error.message,
                color: 'red',
              })
            })
            .finally(() => {
              this.submitting = false
            })
        }
      },
    },
  }
</script>
