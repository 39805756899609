<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="d-flex flex-grow-1 flex-row justify-space-between align-center">
        <span>{{ internalValue.data.name }}</span>
        <div class="d-flex flex-row align-center">
          <v-chip small label>
            {{ reviewTimestamp }}
          </v-chip>
          <span v-if="internalValue.author_id === userId">
            <v-tooltip top>
              <template v-slot:activator="{onTooltip}">
                <v-dialog>
                  <template v-slot:activator="{on}">
                    <v-btn icon v-on="{ ...on, ...onTooltip }"><v-icon>mdi-pencil-box</v-icon></v-btn>
                  </template>
                  <v-card
                    :dark="theme.website.product.card.dark"
                    :light="theme.website.product.card.light"
                    :color="theme.website.product.card.color"
                  >
                    <v-card-title>{{ $t('components.website.productReviews.updateTitle') }}</v-card-title>
                    <v-card-text>
                      <product-review-form
                        :value="internalValue"
                        :product-id="productId"
                        :review-id="internalValue.id"
                        @input="onReviewEdited"
                      />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </template>
              {{ $t('components.website.productReviews.editReview') }}
            </v-tooltip>
          </span>
        </div>
      </v-list-item-title>
      <v-list-item-subtitle v-if="internalValue.stars">
        <v-rating small dense readonly :value="internalValue.stars" />
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <pre>{{ internalValue.message }}</pre>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import TimestampFormatter from '@peynman/press-vue-core/mixins/TimestampFormatter'
  import ProductReviewForm from './ProductReviewForm.vue'

  export default {
    name: 'ProductReviewItem',
    components: { ProductReviewForm },
    mixins: [
      TimestampFormatter,
    ],
    props: {
      value: Object,
      userId: [Number, null],
      productId: Number,
      index: Number,
    },
    data: vm => ({
      internalValue: vm.value,
    }),
    computed: {
      reviewTimestamp () {
        return this.getRelativeTimestamp(Date.parse(this.internalValue.created_at))
      },
    },
    watch: {
      value () {
        this.internalValue = this.value
      },
    },
    methods: {
      onReviewEdited (review) {
        this.internalValue = review
      },
    },
  }
</script>
